import { UserDto } from 'api/models';
import { FIELDS, FormikValues } from '../usersList.types';

const mapRolesToNames = (role: number) => {
  switch (role) {
    case 3:
      return 'Administrator';
    case 4:
      return 'Pracownik';
    case 5:
      return 'Użytkownik bez dostępu do serwisu';
    case 7:
      return 'Użytkownik tylko raportów o NIP';
    case 100:
      return 'Konto techniczne';
    default:
      return '-';
  }
};

export const createRows = (usersData?: UserDto[]) => {
  if (!usersData) return [];

  return usersData.map((result) => ({
    id: result.id,
    [FIELDS.FULL_NAME]: result.fullName || '-',
    [FIELDS.ROLE]: mapRolesToNames(result.role),
    [FIELDS.COMPANY_NAME]: result.companyName || '-',
    [FIELDS.TAX_NUMBER]: result.companyTaxIdentificationNumber || '-',
    [FIELDS.INTEGRATOR_NAME]: result.integratorName || '-',
    [FIELDS.ERP_NAME]: result.erpName || '-',
    [FIELDS.IS_ACTIVE]: !!result.isActive,
    [FIELDS.EMAIL]: result.email,
    [FIELDS.IS_COMPANY_ACTIVE]: result.isCompanyActive
  }));
};

export const createFiltersQuery = (
  CompanyId?: string | number,
  IntegratorId?: string | number,
  Role?: string | number
) => {
  let filtersQuery = '';

  if (CompanyId) {
    filtersQuery += `&CompanyId=${CompanyId}`;
  }

  if (IntegratorId) {
    filtersQuery += `&IntegratorId=${IntegratorId}`;
  }

  if (Role) {
    filtersQuery += `&Role=${Role}`;
  }

  return filtersQuery;
};

export const createSearchQuery = (values: FormikValues, sessionFilters: string, searchInputName?: string) => {
  let searchArray: string[] = [];
  const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
  const keys = Object.keys(parsedSessionFilters);

  keys.forEach((key) => {
    if (parsedSessionFilters[key as keyof typeof parsedSessionFilters]) {
      switch (key) {
        case FIELDS.FULL_NAME:
          searchArray.push(`${FIELDS.FULL_NAME};contains;${parsedSessionFilters[key]}`);
          break;
        case FIELDS.COMPANY_NAME:
          searchArray.push(`${FIELDS.COMPANY_NAME};contains;${parsedSessionFilters[key]}`);
          break;
        case FIELDS.TAX_NUMBER:
          searchArray.push(`${FIELDS.TAX_NUMBER};contains;${parsedSessionFilters[key]}`);
          break;
        case FIELDS.ERP_NAME:
          searchArray.push(`${FIELDS.ERP_NAME};contains;${parsedSessionFilters[key]}`);
          break;
        case FIELDS.INTEGRATOR_NAME:
          searchArray.push(`${FIELDS.INTEGRATOR_NAME};contains;${parsedSessionFilters[key]}`);
          break;
      }
    }
  });

  if (searchInputName) {
    const searchValueExist = searchArray.some((el: string) => el.includes(`${searchInputName};contains`));
    if (searchValueExist) {
      searchArray = searchArray.filter((el: string) => !el.includes(`${searchInputName};contains`));
    }
    searchArray.push(`${searchInputName};contains;${values[searchInputName as keyof typeof values]}`);
  }

  return searchArray.join('|');
};
