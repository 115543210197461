import { PATH } from 'router/routes';
import { ROLE } from 'config/oidc';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddchartIcon from '@mui/icons-material/Addchart';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import RestoreIcon from '@mui/icons-material/Restore';
import { NewReportIcon, ReportDownloadIcon } from 'assets/images/icons';
import { FC } from 'react';
import { FIELDS } from 'domains/user/shared/types/userForm.types';

export type navigationItem = {
  path: PATH;
  label: string;
  Icon: FC<any>;
  alt: string;
  roles?: ROLE[];
  bold?: boolean;
  separator?: boolean;
  disableActiveState?: boolean;
  action?: (event: any) => void;
  hideFor?: string;
};

export type naviagtionType = navigationItem[];

export const navItems: naviagtionType = [
  {
    path: PATH.CUSTOMERS_LIST,
    label: 'Lista klientów',
    Icon: ManageAccountsIcon,
    alt: 'customers list link',
    roles: [ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.ADMIN_KG, ROLE.CURATOR]
  },
  {
    path: PATH.REPORTS_HISTORY,
    label: 'Historia pobranych raportów',
    Icon: ReportDownloadIcon,
    alt: 'reports history list link',
    roles: [ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.REPORT_ONLY_USER]
  },
  {
    path: PATH.HISTORY_CHANGES,
    label: 'Historia zmian na klientach',
    Icon: RestoreIcon,
    alt: 'history changes list link',
    roles: [ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR]
  },
  {
    path: PATH.USERS_LIST,
    label: 'Użytkownicy i uprawnienia',
    Icon: AccountCircleIcon,
    alt: 'users list link',
    roles: [ROLE.CLIENT_ADMIN, ROLE.INTEGRATOR, ROLE.ADMIN_KG, ROLE.CURATOR]
  },
  {
    path: PATH.REPORTS_LIST,
    label: 'Kreator raportów cyklicznych',
    Icon: AddchartIcon,
    alt: 'reports list link',
    roles: [ROLE.CLIENT_ADMIN, ROLE.CURATOR]
  },
  {
    path: PATH.PERIODIC_REPORTS_HISTORY,
    label: 'Historia raportów cyklicznych',
    Icon: AddchartIcon,
    alt: 'reports list link',
    roles: [ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR]
  },
  {
    path: PATH.CSV_LIST,
    label: 'Import danych - CSV',
    Icon: ListAltIcon,
    alt: 'csv list link',
    roles: [ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR]
  },
  {
    path: PATH.DATA_EXPORT,
    label: 'Historia eksportu danych',
    Icon: VerticalAlignBottomIcon,
    alt: 'data export list link',
    roles: [ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR]
  },
  {
    path: PATH.REPORTS_HISTORY,
    label: 'Nowy raport o dowolnym NIP',
    Icon: NewReportIcon,
    alt: 'new report',
    roles: [ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.REPORT_ONLY_USER],
    hideFor: FIELDS.HAS_REPORT_PERMISSION,
    bold: true,
    separator: true,
    disableActiveState: true,
    action: () => {
      localStorage.setItem('showNewReportModal', '1');
    }
  },
  {
    path: PATH.TUTORIAL,
    label: 'Samouczek',
    separator: true,
    Icon: HelpOutlineIcon,
    alt: 'turorial list',
    roles: [ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.INTEGRATOR, ROLE.ADMIN_KG, ROLE.CURATOR, ROLE.REPORT_ONLY_USER]
  }
];
