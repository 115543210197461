import { Routes as RouterRoutes, Route } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { PATH } from './routes';
import {
  UsersList,
  UserProfile,
  AddUser,
  EditUser,
  NotAuthorized,
  Page404,
  SwaggerAuthorize,
  CustomersList,
  AddCustomer,
  SessionExpired,
  CustomerProfile,
  EditCustomer,
  TaxIdentifier,
  Tutorial,
  CsvList,
  CsvErrors,
  ReportsList,
  NewReport,
  EditReport,
  DataExportList,
  HistoryChangesList,
  ReportsHistoryList
} from 'domains';
import { Root } from 'pages';
import { ROLE } from 'config/oidc';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AccessDenied from 'domains/accessDenied';
import { HangFireAuthorize } from 'domains/hangfire';
import { PeriodicReportsHistoryList } from 'domains/periodicReportsHistory';

function Routes() {
  const createRoutes = () => {
    return (
      <>
        <Route path={PATH.ACCESS_DENIED} element={<PublicRoute component={AccessDenied}></PublicRoute>} />
        <Route path={PATH.NOT_AUTHORIZED} element={<PublicRoute component={NotAuthorized}></PublicRoute>} />
        <Route path={PATH.SESSION_EXPIRED} element={<PublicRoute component={SessionExpired}></PublicRoute>} />

        <Route
          path={PATH.ROOT}
          element={
            <ProtectedRoute
              component={Root}
              roles={[
                ROLE.ADMIN_KG,
                ROLE.INTEGRATOR,
                ROLE.CLIENT_ADMIN,
                ROLE.CLIENT_USER,
                ROLE.CURATOR,
                ROLE.REPORT_ONLY_USER
              ]}></ProtectedRoute>
          }
        />
        <Route
          path={PATH.USERS_LIST}
          element={
            <ProtectedRoute
              component={UsersList}
              roles={[ROLE.ADMIN_KG, ROLE.INTEGRATOR, ROLE.CLIENT_ADMIN, ROLE.CURATOR]}
            />
          }
        />
        <Route
          path={`${PATH.USER_PROFILE}/:id`}
          element={
            <ProtectedRoute
              component={UserProfile}
              roles={[ROLE.ADMIN_KG, ROLE.INTEGRATOR, ROLE.CLIENT_ADMIN, ROLE.CURATOR]}
            />
          }
        />
        <Route
          path={PATH.ADD_USER}
          element={
            <ProtectedRoute
              component={AddUser}
              roles={[ROLE.ADMIN_KG, ROLE.INTEGRATOR, ROLE.CLIENT_ADMIN, ROLE.CURATOR]}
            />
          }
        />
        <Route
          path={`${PATH.EDIT_USER}/:id`}
          element={
            <ProtectedRoute
              component={EditUser}
              roles={[ROLE.ADMIN_KG, ROLE.INTEGRATOR, ROLE.CLIENT_ADMIN, ROLE.CURATOR]}
            />
          }
        />
        <Route
          path={PATH.CUSTOMERS_LIST}
          element={
            <ProtectedRoute
              component={CustomersList}
              roles={[ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.ADMIN_KG, ROLE.CURATOR]}
            />
          }
        />
        <Route
          path={PATH.DATA_EXPORT}
          element={
            <ProtectedRoute component={DataExportList} roles={[ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR]} />
          }
        />
        <Route
          path={PATH.HISTORY_CHANGES}
          element={
            <ProtectedRoute
              component={HistoryChangesList}
              roles={[ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR]}
            />
          }
        />
        <Route
          path={PATH.REPORTS_HISTORY}
          element={
            <ProtectedRoute
              component={ReportsHistoryList}
              roles={[ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.REPORT_ONLY_USER]}
            />
          }
        />
        <Route
          path={PATH.ADD_CUSTOMER}
          element={
            <ProtectedRoute component={AddCustomer} roles={[ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR]} />
          }
        />
        <Route
          path={`${PATH.CUSTOMER_PROFILE}/:id`}
          element={
            <ProtectedRoute component={CustomerProfile} roles={[ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR]} />
          }
        />
        <Route
          path={`${PATH.EDIT_CUSTOMER}/:id`}
          element={
            <ProtectedRoute
              component={EditCustomer}
              roles={[ROLE.ADMIN_KG, ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR]}
            />
          }
        />
        <Route
          path={`${PATH.CUSTOMER_TAX_IDENTIFIER}/:taxId`}
          element={
            <ProtectedRoute component={TaxIdentifier} roles={[ROLE.ADMIN_KG, ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER]} />
          }
        />
        <Route
          path={PATH.CSV_LIST}
          element={<ProtectedRoute component={CsvList} roles={[ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR]} />}
        />
        <Route
          path={`${PATH.CSV_ERRORS}/:id`}
          element={<ProtectedRoute component={CsvErrors} roles={[ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR]} />}
        />
        <Route
          path={PATH.REPORTS_LIST}
          element={<ProtectedRoute component={ReportsList} roles={[ROLE.CLIENT_ADMIN, ROLE.CURATOR]} />}
        />
        <Route
          path={PATH.NEW_REPORT}
          element={<ProtectedRoute component={NewReport} roles={[ROLE.CLIENT_ADMIN, ROLE.CURATOR]} />}
        />
        <Route
          path={`${PATH.EDIT_REPORT}/:id`}
          element={<ProtectedRoute component={EditReport} roles={[ROLE.CLIENT_ADMIN, ROLE.CURATOR]} />}
        />
        <Route
          path={PATH.PERIODIC_REPORTS_HISTORY}
          element={
            <ProtectedRoute
              component={PeriodicReportsHistoryList}
              roles={[ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR]}
            />
          }
        />
        <Route
          path={`${PATH.SWAGGER_AUTHORIZE}`}
          element={
            <ProtectedRoute
              component={SwaggerAuthorize}
              roles={[ROLE.ADMIN_KG, ROLE.INTEGRATOR, ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER]}
            />
          }
        />
        <Route
          path={PATH.TUTORIAL}
          element={
            <ProtectedRoute
              component={Tutorial}
              roles={[
                ROLE.CLIENT_USER,
                ROLE.CLIENT_ADMIN,
                ROLE.INTEGRATOR,
                ROLE.ADMIN_KG,
                ROLE.CURATOR,
                ROLE.REPORT_ONLY_USER
              ]}
            />
          }
        />
        <Route
          path={`${PATH.HANGFIRE_AUTHORIZE}`}
          element={
            <ProtectedRoute
              component={HangFireAuthorize}
              roles={[ROLE.ADMIN_KG, ROLE.INTEGRATOR, ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.REPORT_ONLY_USER]}
            />
          }
        />
        <Route path="*" element={<PublicRoute component={Page404}></PublicRoute>} />
      </>
    );
  };

  return <RouterRoutes>{createRoutes()}</RouterRoutes>;
}

export default Routes;
