import { WebStorageStateStore } from 'oidc-client';

export const userManagerConfig = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  authority: process.env.REACT_APP_ISSUER,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  automatic_silent_renew: true,
  silent_redirect_uri: process.env.REACT_APP_REDIRECT_URI + '/sessionexpired',
  load_user_info: true,
  revokeAccessTokenOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: true
};

export enum ROLE {
  SUPER_ADMIN = 'SuperAdmin',
  ADMIN_KG = 'KgAdmin',
  CLIENT_ADMIN = 'ClientAdmin',
  CLIENT_USER = 'ClientUser',
  INTEGRATOR = 'Integrator',
  USER_WITHOUT_WEB_ACCESS = 'WithoutLoginUser',
  CURATOR = 'Curator',
  TECH_ACCOUNT = 'TechAccount',
  REPORT_ONLY_USER = 'ReportOnlyUser'
}

export enum ROLE_MAP {
  KgAdmin = 1,
  Integrator = 2,
  ClientAdmin = 3,
  ClientUser = 4,
  UserWithoutWebAccess = 5,
  Curator = 6,
  ReportOnlyUser = 7,
  TechAccount = 100
}
