import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Paper,
  Breadcrumbs,
  PageTitle,
  PageSubtitle,
  SwitchSection,
  Table,
  Loader,
  Dialog,
  StyledButton,
  StyledRadioButton,
  Tooltip
} from 'components';
import { PATH } from 'router/routes';
import { createRowData } from 'lib/utils';
import { RefreshTokenDto, UserDto } from 'api/models';
import { getAssignedReports, getRefreshToken, getUser, removeUser } from 'api/services';
import { useFetch, useToggle, useSendRequest } from 'lib/hooks';
import { ROLE_MAP, ROLE } from 'config/oidc';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { FormControlLabel } from '@mui/material';
import { mapRolesToNames } from '../shared/types/userForm.types';
import { Helmet } from 'react-helmet-async';

export const tooltipClientAdmin =
  'Administrator firmy może: zarządzać użytkownikami, zarządzać raportami e-mail z listą klientów.';
export const tooltipClientUser = 'Pracownik firmy może zalogować się do serwisu i przeglądać jego zawartość.';
export const tooltipUserWithoutWebAccess =
  'Użytkownik bez dostępu do serwisu nie może zalogować się do serwisu i przeglądać jego zawartości.';
export const tooltipTechAccount = 'Konto techniczne służy wyłącznie do skonfigurowania automatycznego importu CSV.';
export const tooltipReportOnlyUser =
  'Użytkownik tylko raportów o NIP może korzystać wyłącznie z funkcjonalności pobierania raportu o NIP. Pozostałe funkcjonalności są niedostępne, w tym cykliczne raporty.';

const UserProfile = () => {
  const [isModalOpen, toggleModal] = useToggle();
  const [reports, setReports] = useState<any[]>(() => []);
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [newRefreshToken, setNewRefreshToken] = useState<string>('');

  const { id } = useParams();
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const userEmail = auth?.userData?.profile?.Email;

  const { result, isLoading } = useFetch<UserDto>(useCallback(() => getUser(id || ''), [id]));
  const { isLoading: isDeleteLoading, sendData } = useSendRequest();
  const { result: refreshToken, isLoading: isTokenLoading, sendData: sendToken } = useSendRequest();

  const { result: assignedReports } = useFetch<any>(useCallback(() => getAssignedReports(id || ''), [id]));

  useEffect(() => {
    if (assignedReports?.length > 0) {
      setReports(JSON.parse(assignedReports));
    }
  }, [assignedReports]);

  const breadcrumbs = [{ to: PATH.USERS_LIST, label: 'Użytkownicy i uprawnienia' }, { label: result?.fullName || '' }];

  const userData = [
    createRowData('Imię', result?.firstName, undefined, 'user-details-firstname'),
    createRowData('Nazwisko', result?.lastName, undefined, 'user-details-lastname'),
    createRowData('Nr telefonu', result?.phone, undefined, 'user-details-phone'),
    createRowData('Adres e-mail', result?.email, undefined, 'user-details-email'),
    createRowData('Firma', result?.companyName, undefined, 'user-details-company')
  ];

  const submitCallback = useCallback(() => {
    window.close();
  }, []);

  const deleteHandler = useCallback(() => removeUser(id || ''), [id]);

  const deleteUser = () => sendData(deleteHandler, submitCallback, toggleModal);

  const generateToken = () =>
    sendToken(
      () => getRefreshToken(id || ''),
      () => setNewRefreshToken((refreshToken as RefreshTokenDto).refreshToken)
    );

  const showToken = () => {
    generateToken();
    setIsTokenModalOpen(true);
  };

  useEffect(() => {
    const token = refreshToken as RefreshTokenDto;
    if (token?.refreshToken) {
      setNewRefreshToken(token.refreshToken);
    }
  }, [refreshToken]);

  const reportsList = () => {
    if (
      result?.role &&
      ![ROLE_MAP.ClientAdmin, ROLE_MAP.UserWithoutWebAccess, ROLE_MAP.ClientUser].includes(result.role)
    )
      return null;
    if (!(reports.length > 0)) return null;

    return (
      <>
        <Box sx={{ borderTop: '1px solid #E0E0E0', width: '100%', margin: '1rem 0 0 0' }}></Box>
        <Box sx={{ padding: '3rem 1.5rem', width: '60%' }}>
          <PageSubtitle subtitle="Raporty cykliczne e-mail" />
          <Box sx={{ margin: '1.5rem 0 0 0' }}>
            {reports &&
              reports.map((report, index) => {
                return (
                  <Box
                    key={report.id}
                    sx={{
                      display: 'flex',
                      border: '1px solid #C4C4C4',
                      borderRadius: index === 0 ? '0.25rem' : '0 0 0.25rem 0.25rem',
                      borderTop: index === 0 ? '' : 'none'
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        padding: '0 3rem 0 0.75rem',
                        height: '3.4rem'
                      }}>
                      {report.name}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>
          RiskRadar - {result?.firstName ?? ''} {result?.lastName ?? ''}
        </title>
      </Helmet>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper overflow fullSize>
        <Loader isLoading={isLoading}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />

          <Box sx={{ padding: '1.5rem 0 0.75rem 0' }}>
            <PageTitle sx={{ padding: '0 1.5rem' }} dataTest="user-details-title">
              Użytkownik
            </PageTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ padding: '0 1.5rem', width: '60%' }}>
                <PageSubtitle subtitle="Dane użytkownika" dataTest="user-details-subtitle" />
                <Box sx={{ marginTop: '1rem' }}>
                  <Table
                    style={{
                      borderRadius: '3px',
                      borderCollapse: 'collapse',
                      borderStyle: 'hidden',
                      boxShadow: '0 0 0 1px #e0e0e0'
                    }}
                    rows={userData}
                    label="dane użytkownika"
                    boldValues
                  />
                </Box>
              </Box>
              <Box sx={{ borderTop: '1px solid #E0E0E0', width: '100%', margin: '4rem 0 0 0' }}></Box>
              <Box sx={{ padding: '3rem 1.5rem 0 1.5rem', width: '60%' }}>
                <PageSubtitle subtitle="Rola użytkownika" />
                <Box sx={{ margin: '1rem 0 1.5rem .25rem' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value={ROLE_MAP.ClientAdmin}
                      name={'role'}
                      key={ROLE_MAP.ClientAdmin}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={result?.role === ROLE_MAP.ClientAdmin}
                        />
                      }
                      label={mapRolesToNames(ROLE_MAP.ClientAdmin)}
                    />
                    <Tooltip text={tooltipClientAdmin} />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value={ROLE_MAP.ClientUser}
                      name={'role'}
                      key={ROLE_MAP.ClientUser}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={result?.role === ROLE_MAP.ClientUser}
                        />
                      }
                      label={mapRolesToNames(ROLE_MAP.ClientUser)}
                    />
                    <Tooltip text={tooltipClientUser} />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value={ROLE_MAP.UserWithoutWebAccess}
                      name={'role'}
                      key={ROLE_MAP.UserWithoutWebAccess}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={result?.role === ROLE_MAP.UserWithoutWebAccess}
                        />
                      }
                      label={mapRolesToNames(ROLE_MAP.UserWithoutWebAccess)}
                    />
                    <Tooltip text={tooltipUserWithoutWebAccess} />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value={ROLE_MAP.TechAccount}
                      name={'role'}
                      key={ROLE_MAP.TechAccount}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={result?.role === ROLE_MAP.TechAccount}
                        />
                      }
                      label={mapRolesToNames(ROLE_MAP.TechAccount)}
                    />
                    <Tooltip text={tooltipTechAccount} />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      value={ROLE_MAP.ReportOnlyUser}
                      name={'role'}
                      key={ROLE_MAP.ReportOnlyUser}
                      control={
                        <StyledRadioButton
                          sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                          checked={result?.role === ROLE_MAP.ReportOnlyUser}
                        />
                      }
                      label={mapRolesToNames(ROLE_MAP.ReportOnlyUser)}
                    />
                    <Tooltip text={tooltipReportOnlyUser} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ borderTop: '1px solid #E0E0E0', width: '100%', margin: '2rem 0 0 0' }}></Box>
          <Box sx={{ padding: '3rem 1.5rem', width: '60%' }}>
            <PageSubtitle subtitle="Uprawnienia dodatkowe" />
            <Box sx={{ margin: '1.5rem 0 0 0' }}>
              <SwitchSection
                label="Włączanie i wyłączanie monitorowania klienta"
                borderRadius="0.25rem 0.25rem 0 0"
                isChecked={!!result?.hasClientMonitoringPermission}
                onChange={() => {}}
                disabled={true}
              />
              <SwitchSection
                label="Możliwość pobierania aktualnego raportu o kliencie"
                borderRadius="0 0 0.25rem 0.25rem"
                borderTop="none"
                isChecked={!!result?.hasClientReportPermission}
                onChange={() => {}}
                disabled={true}
              />
              <SwitchSection
                label="​Możliwość zarządzania importem danych z CSV"
                borderRadius="0 0 0.25rem 0.25rem"
                borderTop="none"
                isChecked={!!result?.hasCsvPermission}
                onChange={() => {}}
                disabled={true}
              />
            </Box>
          </Box>
          {reportsList()}
          <Box sx={{ borderTop: '1px solid #E0E0E0', width: '100%', margin: '1rem 0 0 0' }}></Box>
          <Stack spacing={3} direction="row" sx={{ padding: '2.4rem 1.5rem', textTransform: 'uppercase' }}>
            <StyledButton
              variant="contained"
              //@ts-ignore //@todo
              component={Link}
              dataTest="user-details-modify-button"
              to={`${PATH.EDIT_USER}/${id}`}>
              Modyfikuj
            </StyledButton>
            <Button
              variant="outlined"
              color="error"
              onClick={toggleModal}
              disabled={userRole === ROLE.CLIENT_ADMIN && result?.email === userEmail}
              startIcon={<DeleteIcon color="error" />}>
              Usuń
            </Button>
            {result?.role === ROLE_MAP.TechAccount &&
              (userRole === ROLE.CLIENT_ADMIN || userRole === ROLE.ADMIN_KG || userRole === ROLE.INTEGRATOR) &&
              result?.isActive && (
                <Button variant="outlined" color="success" onClick={showToken}>
                  Generuj nowy token
                </Button>
              )}
          </Stack>
        </Loader>

        <Dialog
          isOpen={isModalOpen}
          isPending={isDeleteLoading}
          closeModal={toggleModal}
          accept={deleteUser}
          acceptLabel="Tak"
          closeLabel="Nie">
          Czy chcesz trwale usunąć konto użytkownika z Aplikacji RiskRadar?
        </Dialog>

        {result?.role === ROLE_MAP.TechAccount &&
          (refreshToken as RefreshTokenDto)?.refreshToken &&
          (userRole === ROLE.CLIENT_ADMIN || userRole === ROLE.ADMIN_KG || userRole === ROLE.INTEGRATOR) && (
            <Dialog
              isOpen={isTokenModalOpen}
              isPending={isTokenLoading}
              closeModal={() => setIsTokenModalOpen(false)}
              accept={() => {}}
              acceptLabel=""
              closeLabel="Zamknij">
              <Box sx={{ display: 'flex', margin: '0 0 1rem 0', fontWeight: 'bold', justifyContent: 'center' }}>
                Nowy refresh token
              </Box>
              {newRefreshToken}
            </Dialog>
          )}
      </Paper>
    </Box>
  );
};

export default UserProfile;
