import { Navigate } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { PATH } from 'router/routes';
import { Paper } from 'components';
import { logoWhite } from 'assets/images/logo';
import { ROLE } from 'config/oidc';
import { LogoContainer } from './root.styled';

const Root = () => {
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;

  if ([ROLE.ADMIN_KG, ROLE.INTEGRATOR, ROLE.CURATOR].includes(userRole)) {
    return <Navigate to={PATH.USERS_LIST} />;
  }

  if ([ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN].includes(userRole)) {
    return <Navigate to={PATH.CUSTOMERS_LIST} />;
  }

  if ([ROLE.REPORT_ONLY_USER].includes(userRole)) {
    localStorage.setItem('showNewReportModal', '1');
    return <Navigate to={PATH.REPORTS_HISTORY} />;
  }

  return (
    <Paper>
      <LogoContainer>
        <img src={logoWhite} alt="Logo" />
      </LogoContainer>

      <h1>Witamy w RiskRadar</h1>
    </Paper>
  );
};

export default Root;
