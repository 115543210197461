import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { PATH } from 'router/routes';

export enum StatusCode {
  UNAUTHORIZED = 401
}

const axiosInstance: AxiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

const setAuthToken = (request: AxiosRequestConfig) => {
  const authToken = localStorage.getItem('authToken');

  if (request.headers) {
    request.headers['Authorization'] = `Bearer ${authToken}`;
  }

  return request;
};

axiosInstance.interceptors.request.use(setAuthToken);

axiosInstance.interceptors.response.use(undefined, async (error) => {
  const response: AxiosResponse = error.response;

  if (
    response.status === StatusCode.UNAUTHORIZED &&
    window.location.pathname === PATH.SHOW_PERIODIC_REPORT &&
    localStorage.getItem('authToken')
  ) {
    return;
  }

  if (
    response.status === StatusCode.UNAUTHORIZED &&
    window.location.pathname !== PATH.DEACTIVATED_USER &&
    localStorage.getItem('authToken')
  ) {
    window.location.href = PATH.DEACTIVATED_USER;
  }

  if (
    response.status === StatusCode.UNAUTHORIZED &&
    window.location.pathname !== PATH.SESSION_EXPIRED &&
    !localStorage.getItem('authToken')
  ) {
    window.location.href = PATH.SESSION_EXPIRED;
  }
  throw response;
});

export default axiosInstance;
